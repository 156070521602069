<template>
  <main class="solana-page">
    <h1 class="solana-title"> {{ $t('solana.all_pools') }} </h1>

    <div class="all-pools">

      <div class="all-pools-cards">
        <SolanaPoolCard
          v-for="(card, i) of cards"
          :key="i"
          :card="card"
          url="/solana/stake"
        />
      </div>

    </div>
  </main>
</template>

<script>
import SolanaPoolCard from '@/components/Solana/SolanaPoolCard';

export default {
  name: 'Solana',
  components: { SolanaPoolCard },
  data() {
    return {
      cards: [
        {
          image: '1.png',
          name: 'SHT/ETH',
          sub_title: '',
          liquidity: '$ 157,003.32',
          average: '42%',
          link: '',
          disable: true,
        },
        {
          image: '2.png',
          name: 'SHT',
          sub_title: '',
          liquidity: '$ 157,003.32',
          average: '42%',
          link: '',
          disable: false,
        },
        {
          image: '3.png',
          name: 'SHT',
          sub_title: 'Borrowable',
          liquidity: '$ 157,003.32',
          average: '42%',
          link: '',
          disable: true,
        },
      ],
    };
  },
};
</script>

<style lang="scss">
</style>
