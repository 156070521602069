<template>
  <div class="solana-pool-card" :class="light">
    <div class="solana-pool-card-head light-text">
      <img :src="require(`@/assets/img/solana/cards/${card.image}`)" :alt="card.name">
      <div>
        {{ card.name }}
        <div class="solana-pool-card-sub" v-if="card.sub_title">
          {{ card.sub_title }}
        </div>
      </div>
    </div>

    <div class="solana-pool-card-items">
      <div class="solana-pool-card-item">
        <span class="light-text2"> {{ $t('solana.liquidity') }} </span>
        <span class="light-text"> {{ card.liquidity }} </span>
      </div>
      <div class="solana-pool-card-item">
        <span class="light-text2">{{ $t('solana.average') }} (%)</span>
        <span class="light-text"> {{ card.average }} </span>
      </div>
    </div>

    <div class="solana-pool-card-footer">
      <Button
        :class="`no-shadow w-100 btn-solana ${light ? '' : 'btn-solana-pink'}`"
        :to="url"
        :disabled="card.disable"
      > {{ $t('solana.buttons.more') }} </Button>
    </div>
  </div>
</template>

<script>
import Button from '@/components/Button';

export default {
  name: 'SolanaPoolCard',
  components: { Button },
  props: {
    card: Object,
    light: {
      type: Boolean,
      default: false,
    },
    url: String,
  },
};
</script>

<style lang="scss">
</style>
